<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
                v-btn.no-margin(absolute dark fab top right color='primary' @click.stop="drawerRight = !drawerRight")
                    v-icon search
            v-navigation-drawer.filters.grey.darken-3(fixed v-model="drawerRight" right app width="246")
                v-form
                    v-toolbar.grey.darken-1(flat)
                        v-text-field(v-model="filter.query" :placeholder="$t('Buscar...')" dark)
                        v-icon(dark) search
                    .filters-content
                        .white--text.mb-2 {{$t('Filtrar por')}}:
                        v-select.mb-3(
                            v-model="filter.country"
                            :items='country'
                            :label='$t("País")' 
                            item-text="name"
                            item-value="uuid"
                            append-icon="keyboard_arrow_down"
                            autocomplete
                            :filter="vFilter"
                            dark
                            color="white"
                            @input="fetchState"
                        )
                        v-select.mb-3(
                            v-model="filter.state"
                            :items='state'
                            :label='$t("Estado/Departamento/Provincia")' 
                            item-text="name"
                            item-value="uuid"
                            append-icon="keyboard_arrow_down"
                            autocomplete
                            :filter="vFilter"
                            dark
                            color="white"
                            @input="fetchCity"
                        )
                        v-select.mb-3(
                            v-model="filter.city"
                            :items='city' 
                            :label='$t("Ciudad/Municipio")' 
                            item-text="name"
                            item-value="uuid"
                            append-icon="keyboard_arrow_down" 
                            autocomplete
                            :filter="vFilter"
                            dark
                            color="white"
                            @change="zonei=-1"
                            @input="fetchZone"
                        )
                        template(v-if="zones.length")
                            template(v-for="(zone, i) in zones")
                                v-select.mb-3(
                                    v-model="filter.zones[i]"
                                    :items='zone'
                                    item-value="uuid"
                                    item-text="name"
                                    :label='zone[0].division'
                                    append-icon="keyboard_arrow_down"
                                    autocomplete
                                    :filter="vFilter"
                                    dark
                                    color="white"
                                    @change="zonei=i"
                                    @input="fetchZone"
                                )
                        v-flex(xs12)
                            v-select(
                                v-model="filter.gender"
                                :items='gender'
                                item-value="id"
                                item-text="text"
                                :label='$t("Género")'
                                append-icon="keyboard_arrow_down"
                                autocomplete
                                :filter="vFilter"
                                dark
                                color="white"
                            )
                        v-flex(xs12)
                            v-select(
                                v-model="filter.age"
                                :items='ages'
                                item-value="id"
                                item-text="text"
                                :label='$t("Rango de edad")'
                                append-icon="keyboard_arrow_down"
                                autocomplete
                                :filter="vFilter"
                                dark
                                color="white"
                            )
                        .btns.btns-right
                            v-btn(@click="cleanFilter" color="primary") {{$t('Limpiar')}}
            v-content
                v-container.grid-list-xl(fluid)
                    h1 {{$t('Amigos')}}
                    v-tabs(v-model="tab")
                        v-tab {{ $t('Sugerencias')}}
                        v-tab {{ $t('Mis amigos')}}
                    v-tabs-items(v-model="tab")
                        v-tab-item(:value="0")
                            template(v-if="preload")
                                h3.my-2 {{$t('Cargando')}}...
                            v-data-table(v-else
                                :items='suggestions'
                                :headers='headers'
                                :pagination.sync='pagination'
                                :rows-per-page-items="$root.rowsPageItems"
                                :rows-per-page-text="$root.rowsPageText" 
                                item-key='uuid'
                                :total-items="total"
                                :loading="loading")
                                template(slot='items' slot-scope='props')
                                    tr
                                        td
                                            v-layout
                                                v-flex
                                                    v-avatar.grey.lighten-4(size='27')
                                                        img(v-if="photoUrl(props.item.photo).length" :src="photoUrl(props.item.photo)")
                                                        v-gravatar(v-else :email="props.item.email")
                                                v-flex(xs12 d-flex align-center)
                                                    | {{ props.item.names }}
                                        //td 
                                            a.email-table {{ props.item.email }}
                                        td
                                            add-friend(:user="props.item" :request-id="props.item.friend_request" v-model="props.item.friend_state")
                                template(slot="no-data")
                                    span {{ $t('No se encontraron sugerencias de amistad') }}
                        v-tab-item(:value="1")
                            template(v-if="preload")
                                h3.my-2 {{$t('Cargando')}}...
                            v-data-table(v-else
                                :items='friends'
                                :headers='headerFriends'
                                :pagination.sync='pagination'
                                :rows-per-page-items="$root.rowsPageItems"
                                :rows-per-page-text="$root.rowsPageText" 
                                item-key='uuid'
                                :total-items="total"
                                :loading="loading")
                                template(slot='items' slot-scope='props')
                                    tr
                                        td
                                            v-layout
                                                v-flex
                                                    v-avatar.grey.lighten-4(size='27')
                                                        img(v-if="photoUrl(props.item.photo).length" :src="photoUrl(props.item.photo)")
                                                        v-gravatar(v-else :email="props.item.email")
                                                v-flex(xs12 d-flex align-center)
                                                    | {{ props.item.names }}
                                        td 
                                            a.email-table {{ props.item.email }}
                                        td
                                            add-friend(:user="props.item" :request-id="props.item.friend_request" v-model="props.item.friend_state")
                                template(slot="no-data")
                                    span {{ $t('No se encontraron amigos') }}
</template>

<script>

    import auth from 'mixins/auth'
    import config from 'src/config'
    import _ from 'lodash/debounce'
    import fn from 'mixins/fn'

    export default {
        mixins: [auth, fn],
        metaInfo() {
            return {
                title: this.$t('Amigos')
            }
        },
        data() {
            return {

                // Tabs
                tab: 0,

                // Sugerencias
                total: 0,
                loading: false,
                pagination: {
                    page       : 1,
                    rowsPerPage: 20,
                    sortBy     : 'total',
                    descending: true
                },
                cancelFilter: null,
                filter: {},
                filterSchema: {
                    query    : '',
                    country  : '',
                    state    : '',
                    city     : '',
                    from     : null,
                    to       : null,
                    zones    : [],
                    gender   : '',
                    ages     : ''
                },
                zones: [],
                zonei: -1,
                gender: [
                    {
                        text: 'Masculino',
                        id: 1
                    },
                    {
                        text: 'Femenino',
                        id: 0
                    },
                    {
                        text: 'Otro',
                        id: 2
                    }
                ],
                ages: [
                    {
                        text: 'Hasta 18',
                        id: 1
                    },
                    {
                        text: 'De 19 a 30',
                        id: 2
                    },
                    {
                        text: 'De 31 a 40',
                        id: 3
                    },
                    {
                        text: 'De 41 a 50',
                        id: 4
                    },
                    {
                        text: 'De 51 a 60',
                        id: 5
                    },
                    {
                        text: 'De 61 a 80',
                        id: 6
                    },
                    {
                        text: 'Mayor a 80',
                        id: 7
                    }
                ],
                preload: false,
                headers: [
                    {
                        text: 'Nombre',
                        align: 'left',
                        value: 'names',
                    },
                    /*{
                        text: 'Correo electrónico',
                        align: 'left',
                        value: 'email'
                    },*/
                    {
                        text: '',
                        align: 'left',
                        value: 'actions',
                        sortable: false
                    }
                ],
                headerFriends: [
                    {
                        text: 'Nombre',
                        align: 'left',
                        value: 'names',
                    },
                    {
                        text: 'Correo electrónico',
                        align: 'left',
                        value: 'email'
                    },
                    {
                        text: '',
                        align: 'left',
                        value: 'actions',
                        sortable: false
                    }
                ],
                suggestions   : [],
                friends       : [],
                drawerRight   : false,
                loadingBtn    : false,
                modalDateStart: false,
                modalDateEnd  : false,
                country       : [],
                state         : [],
                city          : []
            }
        },
        computed: {
            profile(){
                return this.$root.profile
            }
        },
        watch: {
            profile: {
                deep: true,
                async handler(){
                    
                    this.filter = Object.assign({}, this.filterSchema)

                    // Estados de la amistad
                    this.handleChannel('/friend_request/state', (dat) => {

                        const key = this.tab == 0 ? 'suggestions' : 'friends'

                        let requestId = {}
                        let idx = this[key].findIndex((f) => f.uuid == dat.friendid)
                        
                        if(idx == -1){
                            return
                        }

                        // Estado pendiente
                        if(dat.state == 2){

                            requestId = {
                                uuid    : dat.requestid,
                                friendid: dat.friendid
                            }
                        }

                        this[key][idx].friend_state = dat.state
                        this[key][idx].friend_request = requestId

                        // Estado aceptado
                        if(this.tab == 0 && dat.state == 1){

                            let idx = this[key].findIndex((f) => f.uuid == dat.friendid)
                            this[key].splice(idx, 1)
                        }

                        // Estado eliminado
                        if(this.tab == 1 && dat.state == 0){

                            let idx = this[key].findIndex((f) => f.uuid == dat.friendid)
                            this[key].splice(idx, 1)
                        }
                    })
                }
            },
            filter: {
                handler(){
                    
                    this.preload = true
                    this.total = 0

                    if(this.tab == 0){
                        this.suggestions = []
                        this.fetchSuggestion()
                    }

                    if(this.tab == 1){
                        this.friends = []
                        this.fetchFriends()
                    }
                },
                deep: true
            },
            pagination: {
                handler () {
                    if(this.tab == 0){
                        this.fetchSuggestion()
                    }
                    if(this.tab == 1){
                        this.fetchFriends()
                    }
                },
                deep: true
            },
            tab(){

                // Sugerencias de amistad
                if(this.tab == 0){

                    this.preload = true
                    this.total = 0
                    this.suggestions = []
                    this.fetchSuggestion()
                }

                // Amigos
                if(this.tab == 1){

                    this.preload = true
                    this.total = 0
                    this.friends = []
                    this.fetchFriends()
                }
            }
        },
        created(){
            this.fetchCountries()
        },
        methods: {

            photoUrl(photo){

                if(photo != null && photo.length){
                    return config.uploaduser + photo
                }
                return ''
            },

            fetchCountries(){

                this.$api(this, (xhr) => {
                    xhr.get('/country').then((r) => {

                        let data = r.data
                        if(data.response){
                            this.country = data.data
                        }
                    }).catch(() => {})
                })
            },

            fetchState(){

                this.$api(this, (xhr) => {
                    xhr.get('/state', {
                        params: {
                            countryid: this.filter.country 
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.state = data.data
                        }
                    }).catch(() => {})
                })
            },

            fetchCity(){

                this.$api(this, (xhr) => {
                    xhr.get('/city', {
                        params: {
                            stateid: this.filter.state 
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.city = data.data
                        }
                    }).catch(() => {})
                })
            },

            fetchSuggestion: _(function(){

                if(this.cancelFilter != null){
                    this.cancelFilter.cancel()
                }

                let CancelToken   = this.$axios.CancelToken
                this.cancelFilter = CancelToken.source()

                this.loading = true
                this.$api(this, (xhr) => {
                    xhr.get('/social/friend/suggestion', {
                        params: {
                            filter : JSON.stringify(this.filter),
                            limit  : this.pagination.rowsPerPage,
                            offset : (this.pagination.page-1)*this.pagination.rowsPerPage,
                            sort   : this.pagination.sortBy,
                            order  : (this.pagination.descending ? 'DESC' : 'ASC'),
                        }
                    }).then((r) => {

                        let data     = r.data
                        this.preload = false
                        this.loading = false

                        if(data.response){

                            this.total       = data.data.total
                            this.suggestions = data.data
                        }
                    }).catch(() => {})
                })

            }, 500),
            
            fetchFriends: _(function(){

                if(this.cancelFilter != null){
                    this.cancelFilter.cancel()
                }

                let CancelToken   = this.$axios.CancelToken
                this.cancelFilter = CancelToken.source()

                this.loading = true
                this.$api(this, (xhr) => {
                    xhr.get('/social/friend/list', {
                        params: {
                            filter : JSON.stringify(this.filter),
                            limit  : this.pagination.rowsPerPage,
                            offset : (this.pagination.page-1)*this.pagination.rowsPerPage,
                            sort   : this.pagination.sortBy,
                            order  : (this.pagination.descending ? 'DESC' : 'ASC'),
                        }
                    }).then((r) => {

                        let data     = r.data
                        this.preload = false
                        this.loading = false

                        if(data.response){

                            this.total   = data.data.total
                            this.friends = data.data
                        }
                    }).catch(() => {})
                })

            }, 500),

            changeSort (column) {
                if (this.pagination.sortBy === column) {
                    this.pagination.descending = !this.pagination.descending
                } else {
                    this.pagination.sortBy = column
                    this.pagination.descending = false
                }
            },

            cleanFilter(){

                this.zones  = []
                this.filter = Object.assign({}, this.filterSchema)
                this.filter.zones = []
            },

            fetchZone(item){

                return new Promise((resolve) => {

                    this.zones = this.zonei == -1 ? [] : this.zones.slice(0, this.zonei+1)

                    this.$api(this, (xhr) => {
                        xhr.get('/zone', {
                            params: {
                                cityid: this.filter.city,
                                parent: this.zonei == -1 ? 0 : item,
                                ab: 1,
                                networkid: this.networkid
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                if(data.data.length){
                                    this.filter.zones.push('')
                                    this.zones.push(data.data)
                                }
                            }
                            resolve()
                        }).catch(() => {})
                    })
                })
            }
        }
    }

</script>